/* All Import */
@import url('./assets/Responsive.css');

/* Common CSS */
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  color: inherit;
  background: transparent;
}
html {
  scroll-behavior: smooth;
  position: relative;
}
body{
  color: #ddd;
  margin: 0;
  padding: 0;
  position: relative;
  user-select: none;
  line-height:1.5;
 
}
img {
  max-width: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.container{
  max-width: 1300px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.d-flex {
  display: flex;
}
.light-grey{
  background: #f5f5f5;
}
.section-title h4{
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: #103178;
  text-align: center;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 600;
}
a {
  text-decoration: none;
}
a:hover {
  color: #FD8D27;
}

section{
  padding: 30px 0 20px;
}
.rangenumber {
  margin-top: 10px;
  border: 1px solid grey;
  padding: 2px 11px;
}
/* Navbar Top */
nav{
  border-bottom: 1px dashed #fcbd00;
  position: relative;
  z-index: 9;
}
.navbar-block .navbar-list.mega-menu h5 {
  font-size: 1.0rem;
}
.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comp-logo img {
  max-width: 216px;
}
.search-table {
  background-color: #f0f2f5;
  border-radius: 30px;
  overflow: hidden;
  width: 40%;
  color:'#ff0000';
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.search-table .form-control {
  border-color: #f0f2f5;
  color: #5b6c8f;
  height: 46px;
  font-size: 18px;
  width: 87.9%;
}
.form-control {
  outline: none;
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 0 20px;
  border: none;
  height: 37px;
  border-radius: 0;
  border: 1px solid #ddd;
  transition: all .4s ease;
  box-shadow: 0 0 0 transparent;
  background-color: transparent;
  line-height: 50px;
  border-radius: 5px;
}
textarea.form-control {
  height: 150px;
}
.input-group-append {
  background: #e8eaee;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #103178;
}
.input-group-append a i {
  color: #103178;
  font-weight: 900;
  font-size: 18px;
}
.icon-holder {
  display: flex;
  justify-content: center;
  list-style: none;
}
.icon {
  position: relative;
  width: 53px;
  height: 50px;
  font-size: 27px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #FD8D27!important;
}
.iconCart a {
  position: relative;
  width: 53px;
  height: 50px;
  font-size: 27px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #ff0000!important;
}
.badgeCustom{
  position: absolute;
    bottom: 55%;
    left: 50%;
    width: 20px;
    height: 20px;
    font-size: 11px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0e0e0fd6;
    color: white;
    padding: 0.25em 0.4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
/* .badge {
  position: absolute;
  bottom: 55%;
  left: 50%;
  width: 15px;
  height: 19px;
  font-size: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #103178d6;
  color: white;
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
} */

/* Navbar Bottom */
.navbar-bottom{
  border-top: 1px dashed #fcbd00;
  background: #f0f2f5;
}
.navbar-block{
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
.navbar-list{
  /* position: relative; */
  display: inline-flex;
  padding: 15px 10px;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  color: #103178;
  text-transform: capitalize;
  /* transform-style: preserve-3d; */
  align-items: center;
  cursor: default;
  /* remove comments if old navbar needed */
}
.navbar-list .drop-icon{
  font-size: 20px;
  margin-left: 2px;
}
.navbar-list .navbar-sublist {
  display: none;
  background: #fff;
  padding: 10px 0px;
}
.navbar-list:hover {
  background-color: #fff;
}
.navbar-list:hover .navbar-sublist {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0 0 10px 10px;
}
.navbar-list .navbar-sublist ul {
  padding: 0px;
  list-style: none;
}
.navbar-list .navbar-sublist ul li {
  padding: 8px 30px;
  font-size: 15px;
  border-bottom: 1px solid #eee;
}
.navbar-list .navbar-sublist ul li:last-of-type {
  border-bottom: none;
}
.navbar-list.mega-menu:hover .mega-menu-inner {
  display: block;
}
.navbar-list.mega-menu .mega-menu-inner {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 85vw;
  background: #fff;
  padding: 10px 20px;
  height: 72vh;
}
.navbar-list.mega-menu .mega-menu-inner ul {
  padding-left: 0;
  list-style: none;
}
.navbar-list.mega-menu .mega-menu-inner ul>li>h5{
  margin: 0;
  padding: 5px 0;
  width: 22%;
  font-size: 14px;
}
.navbar-list.mega-menu .mega-menu-inner .mega-submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 23%;
  border-left: 1px solid #ddd;
  padding: 10px 20px;
  min-height: 95%;
  overflow: hidden;
  width: 74%;
  /* overflow-x: scroll; */
}
.navbar-list.mega-menu .mega-menu-inner>ul>li:hover .mega-submenu {
  display: block;  
}
.navbar-list.mega-menu .mega-menu-inner ul>li .mega-submenu ul>li> button{
  margin: 0;
    padding: 0px 0;
    font-size: 13px;
    text-decoration: none;
    color: #353779;
    font-weight: 400;
    line-height: 10px;
}
.navbar-list.mega-menu .mega-menu-inner ul>li .mega-submenu ul>li> a{
  margin: 0;
    padding: 0px 0;
    font-size: 13px;
    text-decoration: none;
    color: #353779;
    font-weight: 400;
    line-height: 10px;
}
.mega-menu-inner .mega-submenu .sub-head h5{
  font-weight: 600;
  font-size: 16px !important;
  color: #103178;
  text-transform: capitalize;
  /* margin-bottom: 2px !important; */
  width: 100%!important;
  padding: 0!important;
  padding-top: 10px!important;
}
/* for the link tag a i am using this class */
.lastCat {
  display: block;
    line-height: 24px;
    padding: 5px 5px 5px 0;
    font-size: 15px;
    color: #3c5ca0;
    background-color: transparent;
}
.mega-menu-inner .mega-submenu .sub-head:first-of-type h5{
  padding-top: 0px!important;
}
.mega-menu-inner .mega-submenu ul {
  height: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
 .mega-submenu ul::-webkit-scrollbar {
  width: 5px;
  height : 8px
}
 
.mega-submenu ul::-webkit-scrollbar-track {
  background: #ddd;
}
 
.mega-submenu ul::-webkit-scrollbar-thumb {
  background: #666; 
}
.mega-submenu li {
  padding-right: 10px;
  /* width: 19.8%; */
}

/* Home Slider */
.each-slide > div {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-size: cover;

  background-color: #cccccc;
  height: 400px;
  background-position: center;
  background-repeat:  no-repeat, repeat;
  position: relative;
}
.each-slide span {
  position: absolute;
  top: 23%;
  left: 15%;
  font-size: 70px;
  width: 39%;
  font-weight: 600;
  color: #103178;
  line-height: 77px;
  margin-bottom: 10px;
  text-shadow: 1px 1px 1px white;
}
.homeSlider .react-slideshow-container .nav.default-nav {
  height: 80px;
  width: 80px;
  background: #ffffffab;
  z-index: 8;
}
.homeSlider .react-slideshow-container .default-nav:first-of-type {
  margin-right: -80px;
  transform: translateX(30px);
}
.homeSlider .react-slideshow-container .default-nav:last-of-type {
  margin-left: -80px;
  transform: translateX(-30px);
}
/* Mobile Menu */
.mobileNavbar {
  display: none;
}
.menu-group {
  margin-bottom: 2px;
}
.menu-list {
  background: #fff;
  padding: 10px;
  font-size: 16px;
  color: #103178;
  font-weight: 500;
}
.menu-list-icon {
  font-size: 18px;
  color: #FD8D27;
  margin-right: 10px;
  transform: translateY(1px);
}
.menu-all {
  height: calc(100vh - 110px);
  overflow: scroll;
}
.droperIcon {
  position: absolute;
  top: 18%;
  right: 10px;
}
.filter-pro {
  height: 0;
  overflow: hidden;
  transition: .1s ease-in-out;
}
.filter-pro.is-open-pro {
  height: auto;
  overflow: auto;
  /* padding: 11px;
  margin: 4px -10px -10px; */
  background: #eee;
}



.mobile-menu-inner {
  height: 0;
  overflow: hidden;
  transition: .1s ease-in-out;
}
.mobile-menu-inner.is-open {  
  height: auto;
  overflow: auto;
  padding: 11px;
  margin: 4px -10px -10px;
  background: #eee;
}
.mobile-menu-inner2 {
  height: 0;
  overflow: hidden;
  transition: .1s ease-in-out;
}
.mobile-menu-inner2.is-open {  
  height: auto;
  overflow: auto;
  padding: 11px;
  margin: 4px -10px -10px;
  background: #eee;
}
.yes-rotate {
  transform: rotate(180deg)
}
.sub-menu-list {
  padding-left: 12px;
  height: 0;
  overflow: hidden;
}
.mobile-menu-inner h5 {
  font-weight: 500;
  font-size: 14px;
}
.mobile-menu-inner li.sub-head h5 {
  margin-top: 7px;
  font-size: 16px;
}
.mobile-menu-inner  .Collapsible__contentInner ul li {
  margin-left: 20px;
}
.mobile-menu-inner  .Collapsible__contentInner ul li.sub-head {
  margin-left: 0px;
}


.mobile-menu-inner li {
  list-style: none;
}

/* Home Category */
.category--block {
  display:flex;
  flex-wrap: nowrap;
}
.category__thumbnail {
  min-height: 250px;
  max-width: -moz-fit-content;
  margin: 4px;
  position: relative;
  background-color: #f0f2f5;
  border-radius: 10px;
}
.category__image {
  padding: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 61%;
  height: 68%;
}
.category__content {
  padding: 20px;
  display: block;
}
.category__name {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 14px;
  color: #103178;
  display: block;
  font-weight: 500;
  text-decoration: none;
}
.category__more {
  color: #103178;
  background-color: white;
  font-size: 14px;
  padding: 3px 10px;
  min-width: 80px;
  line-height: 24px;
  border-radius: 40px;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
}
.category__more:hover {
  background-color: #103178;
  color: white;
}
.homeCategory .react-multiple-carousel__arrow--right {
  right: calc(6.2% + 1px);
}
.homeCategory .react-multiple-carousel__arrow--left {
  left: calc(3.8% + 1px);
}
.homeCategory .react-multiple-carousel__arrow {
  border-radius: 5px;
  background: rgb(0 0 0 / 18%);
  transform: translateX(20px);
}
.homeCategory .react-multi-carousel-list {
  position: static;
}

/* Product Slider */
.homeProduct {
  background: linear-gradient(180deg, #f0f2f5, #fff);
}
.homeProduct.bg-white {
  background: #fff;
}
.homeProduct .section-title h4 {
  font-size: 33px;
  line-height: 50px;
  color: #103178;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 700;
}
.homeProduct .section-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 10px;
  align-items: center;
}
.homeProduct .viewAllBtn {
  background: #1e3178;
  padding: 8px 10px;
  border-radius: 20px;
  color: #fff;
  border: 1px solid #ccc;
  text-decoration: none;
}
.homeProduct .viewAllBtn:hover {
  background: #FD8D27;
  border-color: #FD8D27;
  color: #fff;
}
.homeProduct .section__product {
  border: 1px solid #e0e0e0;
  margin-right: 10px;
  background: #fff;
  border-radius: 5px;
  display:block;
  transition: .3s ease-in-out;
  overflow: hidden;
  width: 244px;
  height: 299px;
  text-decoration: none;
}
.homeProduct .section__product:hover {
  border-color: #fcbd00;
}
.homeProduct .product__image {
  padding: 20px;
  display: block;
  overflow: hidden;
  width: 242px;
  height: 200px;
}
.homeProduct .section__product:hover .product__image img {
  transform: scale(1.1);
}
.homeProduct .product__content {
  padding: 20px;
  display: block;
  background: linear-gradient(0deg, #f2be2421, transparent);
  height: 99px;
}
.homeProduct .product__title {
  display: block;
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #103178;
  text-decoration: none;
  height: 35px;
  line-height: 17px;
  overflow: hidden;
}
.homeProduct .product__price.sale {
  color: #FD8D27;
}
.homeProduct .product__price {
  color: #103178;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
}
.homeProduct .product__del {
  color: #99a0ae;
  margin-left: 5px;
  text-decoration: line-through;
  font-weight: 400;
  font-size: 16px;
}
.homeProduct .react-multiple-carousel__arrow--right {
  right: calc(7% + 1px);
}
.homeProduct .react-multiple-carousel__arrow--left {
  left: calc(3.8% + 1px);
}
.homeProduct .react-multiple-carousel__arrow {
  border-radius: 5px;
  background: rgb(0 0 0 / 18%);
  transform: translateX(20px);
  height: 80px;
}
.homeProduct .react-multi-carousel-list {
  position: static;
}
.homeProduct .product__actions{
  display:block;
  position: absolute;
  top: 10px;
  right: -30px;
  transition: 100ms ease-in-out;
  opacity: 0;
}
.homeProduct .section__product:hover .product__actions{
  top: 10px;
  right: 20px;
  opacity: 1;
}
.homeProduct .product__item{
  background: #e8e8e8;
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  font-size: 20px;
  padding: 5px;
  color: #333;
}

/* Delivery Banner */
.delivery {
  padding: 30px 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  background-image: url(../public/images/banner/banner-delivery-2.jpg);
}
.delivery .delivery__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.delivery .delivery__text {
  font-size: 24px;
  line-height: 30px;
}
.delivery .delivery__text {
  color: #12a05c;
  font-size: 22px;
  line-height: 26px;
  display: inline-flex;
  align-items: center;
}
.delivery .delivery__more {
  margin-top: 0;
}
.delivery .delivery__more {
  background-color: #12a05c;
  border: 1px solid #12a05c;
  font-size: 18px;
  font-weight: 500;
  color: white;
  min-width: 98px;
  border-radius: 20px;
  text-align: center;
  padding: 4px;
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
}
.delivery .delivery__more:hover {
  background-color: white;
  color: #12a05c;
}

/* Home Product Special */
.homeProduct.homeProductSpecial {
  background: #30d36a;
}
.homeProduct.homeProductSpecial .section-title h4 {
  color: #ffffff;
}

/* FeatureProduct */
.featureBlock {
  display: inline-block;
  border: 1px solid #e0e0e0;
  border-top: none;
  width: 100%;
}
.homeProduct.fetureProduct .section__product:hover{
  border-color: #e0e0e0;
}
.featureBlock .section__product {
  width: 19.924%;
  margin-right: 0;
  border-radius: 0;
  border-left: none;
  border-bottom: none;
  float: left;
  position: relative;
}
.fetureProduct.homeProduct .section__product:hover .product__actions{
  right: 10px;
}

/* Bottomicon */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-md-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.bot-block {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  margin: 0 10px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid #ffcca4;
  box-shadow: 0 0 5px 0 #d0d0d0;
  background: linear-gradient(0deg,#ffcca430, #30d36a66);
}
.bot-block .icon-bot {
  width: 25%;
  text-align: center;
}
.bot-block .icon-bot img {
  background: #fff;
  width: 80%;
  padding: 0px;
  border-radius: 50%;
  border: 1px dashed #d2a733;
}
.bot-block .bot-content {
  width: 75%;  
}
.bot-content h5 {
  color: #333;
  font-size: 20px;
}
.bot-content p {
  color: #7a7575;
  font-size: 14px;
}
.categoryArea {
  padding: 30px 20px;
  color: #878787;
}
.categoryArea h1 {
  line-height: 25px;
  font-size: 25px;
  margin-bottom: 20px;
  color: #103178;
  border-bottom: 1px solid #cecece;
  padding-bottom: 10px;
}
.category-list-block {
  line-height: 25px;
  font-size: 14px;
  margin-bottom: 16px;
}
.category-list-block span {
  text-transform: uppercase;
  font-weight: 500;
  color: #173c8c;
}
.category-list-block a {
  padding: 0 10px;
  position: relative;
  display: inline-block;
}
.category-list-block a::after {
  content: " ";
  position: absolute;
  top: 4px;
  right: 0;
  height: 18px;
  width: 1.5px;
  background-color: #c7c8cc;
}
.category-list-block a:last-of-type:after {
  content: none;
}
.termsArea {
  padding: 30px 20px;
  font-size: 11px;
  color: #878787;
}
.termsArea h2 {
  font-size: 20px;
  margin-bottom: 8px;
}
.footerInner {
  background-color: #103178;
}
.foot-side{
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.footerInnerBlock {
  margin-left: 65px;
  width: 90%;
  padding-top: 20px;
}
.foot-nav-head{
  font-weight: 500;
  margin-bottom: 15px;
  color: #fd8d27;
  font-size: 16px;
}
.foot-nav-list, .foot-nav-social {
  font-size: 13px;
  color: #ffffff;
  display: block;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}
.foot-nav-list:hover{
  color: #FD8D27;
}
.foot-nav-social:hover{
  color: #FD8D27;
}
.address-area {
  font-size: 11px;
  padding-right: 20px;
}
.address-area p{
color:#fff;
line-height: 11px;

}
.copyright {
  text-align: center;
  border-top: 1px solid #fff;
  margin-top: 20px;
  padding-top: 20px;
}
.copyright p{
color:#fff
}

/* Product List Page */
.page-header {
  border-bottom: 2px dotted #d9d8d5;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;
}
.page-title h4 {
  color: #fd8b34;
  font-size: 30px;
}
.page-title h6 {
  color: #777;
  font-weight: 500;
  align-items: center;
  display: flex;
}
.page-title h6 .page-divide {
  font-size: 22px;
  margin-top: 4px;
}
.page-filter {
  color: #fff;
  font-size: 15px;
  border: 1px solid #103178;
  background: #103178;
  padding: 5px 10px;
  border-radius: 5px;
}
.page-filter-icon {  
  font-size: 18px;
  margin-right: 5px;
}

aside.slidepanel {
  width: 20%;
}
.slidepanel-block {
  background: #fff;
  box-shadow: 0 0 4px 0 #ddd;
  padding: 10px;
  margin-bottom: 20px;
}

.slidepanel-block h6 {
  color: #103178;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.slidepanel-label {
  color: #333;
  font-size: 14px;
  padding-bottom: 8px;
  display: block;
  padding-left: 20px;
  position: relative;
}
.slidepanel-label:first-of-type {
  margin-top: 10px;
}
.label-icon {
  color: #333;
  font-size: 15px;
  position: absolute;
  left: 0;
  top: 4px;
}
.label-checkbox {
  position: absolute;
  left: 3px;
  top: 4px;
}
.mainpanel {
  width: 80%
}
.mainpanelProductSearch {
  width: 100%
}
.all-product{
  /* display: flex; */
  border-top: none;
  padding-left: 10px;
  position: relative;
  padding-bottom: 40px;
  flex-wrap: wrap;
}
.all-product div a{
text-decoration: none;
}
.all-product .section__product:hover{
  border-color: #e0e0e0;
}
.all-product .section__product {
  width: 23.824%;
  margin-right: 0;
  border-radius: 0;
  border-left: none;
  border-bottom: none;
  float: left;
  position: relative;
}
.all-product .section__product {
  border: 1px solid #e0e0e0;
  margin-right: 10px;
  background: #fff;
  border-radius: 5px;
  display: block;
  transition: .3s ease-in-out;
  /* overflow: hidden; */
  margin-bottom: 10px;
  /* height: 250px; */
}
.all-product .section__product:hover { 
  border-color: #fcbd00;
  z-index: 0;
}
.all-product .product__image {
  padding: 15px;
  display: block;
  overflow: hidden;
  height: 235px;
  width: 100%;
}
.all-product .product__image  img{
    /* height : 100% */
}
.mainpanel.position-relative .products-loader > div {
  top : 100px !important
}

/* .fluid.react-slick {
  width: 25%;
} */
.all-product .section__product:hover .product__image img {
  transform: scale(1.05);
}
.all-product .product__content {
  padding: 15px;
  display: block;
  background: linear-gradient(0deg, #f2be2421, transparent);
  bottom: 0;
  /* position: absolute; */
  width: 100%;
}
.all-product .product__actions{
  display:block;
  position: absolute;
  top: 10px;
  right: -30px;
  transition: 100ms ease-in-out;
  opacity: 0;
  /* z-index: 99999999; */
}
.all-product .section__product:hover .product__actions{
  top: 10px;
  right: 20px;
  opacity: 1;
  /* z-index: 99999999; */
}
.all-product .product__item{
  background: #e8e8e8;
  margin-bottom: 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  font-size: 19px;
  padding: 5px;
  color: #333;
  box-shadow: 0 0 7px 0 #ddd;
  border: 1px solid #c7c7c7;
}
.all-product .product__item:hover {
  transform: scale(1.03);
}
.all-product .product__title {
  display: block;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #103178;
  text-decoration: none;
  height: 38px;
  line-height: 21px;
  overflow: hidden;
}
.all-product .product__price.sale {
  color: #FD8D27;
}
.all-product .product__price {
  color: #103178;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
}
.all-product .product__del {
  color: #99a0ae;
  margin-left: 5px;
  text-decoration: line-through;
  font-weight: 400;
  font-size: 16px;
}

.pagination {
  /* position: absolute;
  bottom: 0;
  display: flex; */
  justify-content: center;
  width: 100%;
}
.pagination a {
  text-decoration: none;
}
.pagination ul {
  display: flex;
}
.pagination ul li {
  list-style: none;
  border: 1px solid #c4c4c4;
  width: 30px;
  height: 36px;
  margin: 0 3px;
  line-height: 30px;
  text-align: center;
  color: #333;
  border-radius: 5px;
  background: #fff;
}
.pagination ul li:nth-child(5) {
  list-style: none;
  /* border: none; */
  width: 30px;
  height: 36px;
  margin: 0;
  line-height: 30px;
  text-align: center;
  color: #333;
  /* background: transparent; */
}
.pagination-icon {
  font-size: 25px;
  padding-top: 4px;
}

/* Product Detail */
.product-detail-top {
  column-gap: 30px;
  align-items: center;
}
.item-slider {
  width:25%
}
.item-detail {
  width:75%
}
.item-title {
  color: #fd8b34;
  font-size: 30px;
}
.item-cat {
  font-weight: 500;
  color: rgb(42, 42, 42);
  font-size: 13px;
  margin: 10px 0 0px;
  display: flex;
}
.item-cat p {
  border: 1px solid #cfc9c9;
  padding: 2px 10px 4px;
  border-radius: 30px;
  line-height: 1.3;
  margin-right: 7px;
  background: #eee;
  width: fit-content;
  text-align: center;

}
.navbar-list a {
  color: #103178;
text-decoration: none;
}
.item-cat p:hover{
  color:#e68827
}
.item-detail .item-price{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0 3px 0;
  border-top: 1px solid #f9e4e4;
}
.item-detail .item-price .current-price {
  font-size: 45px;
  text-decoration: none;
  font-weight: 700;
  color: #4e4e4e;
  bottom: -10px;
}
.item-detail .item-price .save-price {
  font-size: 13px;
  font-weight: 700;
  margin: 6px 0 -7px 25px;
  display: block;
  color: #3fc32f;
}
.item-detail .item-price .old-price {
  text-decoration: line-through;
  color: #bfbfbf;
  margin-left: 20px;
  font-size: 38px;
  font-weight: 700;
  display: block;
}
.item-detail .item-short-desp {
  color: #323232;
  font-size: 15px;
}
.item-quantity-size h6 {
  color: #a79f9f;
  font-size: 15px;
  font-weight: 500;
}
.item-quantity .item-quantity-inner {
  display: inline-flex;
  padding: 1px;
  padding-bottom: 0.5px;
  margin-top: 10px;
  border-radius: 50px;
  overflow: hidden;
}
.item-quantity .item-quantity-inner span {
  border: 1px solid #ddd;
  width: 36px;
  height: 36px;
  background: #e7dfdf;
  line-height: 36px;
  text-align: center;
  color: #333;
}
.item-quantity .item-quantity-inner span:nth-child(2) {
  width: 80px;
  background: #fff;
}
.item-size-inner {
  column-gap: 5px;
  margin-top: 15px;
}
.item-size .item-size-inner label span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #dbdcdf;
  border-radius: 30px;
  background: #fff;
  color: #333;
  justify-content: center;
  font-size: 13px;
}
.item-size .item-size-inner label input{
  position: absolute;
  opacity: 0;
}
.item-size .item-size-inner label input:checked ~ span {
  background: #103178;
  color: #fff;
}
.item-btn button {
  border: 1px solid #103178;
  padding: 10px 30px;
  margin-top: 20px;
  background: #103178;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
.item-btn button:first-child {
  background: transparent;
  color: #103178;
  /* transform: translateY(6px); */
  padding: 6px 10px;
  margin-right: 3px;
  border-color: #103178;
}

.item-btn-wishlist {
  border: 1px solid #103178;
  background: transparent;
  color: #103178;
  padding: 6px 10px;
  margin-right: 3px;
  border-color: #103178;
}
.item-btn-add-cart {
  border: 1px solid #103178;
  padding: 10px 30px;
  /* margin-top: 20px; */
  background: #103178;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

.item-size {
  margin-left:20px
}
.item-availability, .item-quantity-size {
  border-top: 1px solid #f9e4e4;
  padding-top: 10px;
  position: relative;
}
.item-availability h6 {
  color: #a79f9f;
  font-size: 15px;
  font-weight: 500;
}
.item-availability p {
  position: absolute;
  bottom: -10px;
  font-size: 12px;
  color: #333;
}
.item-availability>div {
  display: inline-flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.item-availability .form-control {
  height: 34px;
  background: #fff;
  border-radius: 3px 0 0 3px;
  width: 200px;
}
.item-availability button {
  background: #103178;
  border-radius: 0 3px 3px 0;
  font-size: 15px;
  padding: 0 10px;
  height: 34px;
  color: #fff;
}
button.btn-hover:hover {
  background-color: #FD8D27;
}
.delivery-resposive {
  /* padding-left: 40px;
  margin-left: 40px;
  border-left: 1px solid #ddd; */
  margin-left: 20px;
}
.delivery-resposive span svg {
  font-size: 20px;
  transform: translate(10px, 4px);
  margin-right: 16px;
  margin-top: -10px;
}
.delivery-resposive span{
  color: green;
  height: 34px;
  line-height: 34px;
}
.delivery-resposive span.success {
  color: green;
}
.delivery-resposive span.danger {
  color: red;
}
.product-detail-bottom {
  padding: 10px;
  margin-top: 70px;
  border: 1px solid #ddd;
  background: #fff;
  padding-top: 0;
}
.product-detail-bottom h4 {
  display: inline-block;
  background: #f5f5f5;
  transform: translate(0px, -19px);
  padding: 5px 20px;
  font-size: 20px;
  color: #103178;
  text-transform: uppercase;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #ddd;
}
.product-detail-bottom p {
  color: #333;
}
.product-detail-bottom>div {
  padding: 0 20px 20px;
}
.order-pagination ul {
  display: flex;
  justify-content: center;
  column-gap: 40px;
  align-items: center;
  position: relative;
}
.order-pagination ul::before {
  content: " ";
  position: absolute;
  top: 50%;
  width: 34%;
  height: 0px;
  border: 2px dashed #fd8d27;

}
.order-pagination ul li {
  list-style: none;
  text-align: center;
  color: #143479;
  font-weight: 500;
  background: #f5f5f5;
  padding: 0 20px;
  z-index: 1;
  user-select: none;
}

/* Cart & Checkout */
.section-main-area {
  column-gap: 30px;
  padding:30px 0;
  align-items: flex-start;
}
.sectionLeft {
  width: 70%;
  border: 1px dashed #1b33786e;
  flex-grow: 1;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 14px 0 #ddd;
}
.sectionRight {
  width: 30%;
  padding:18px;
  background:#fff;
  border: 1px dashed #1b33786e;
  border-radius: 10px;
  box-shadow: 0 0 14px 0 #ddd;
}
.section-title {
  color: #103178;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  border-bottom: 1px solid #ddd;
  padding-bottom: 3px;
  margin-bottom: 10px;
}
.cart-item {
  column-gap: 20px;
  border-top: 1px dashed #ffd5d5db;
  padding-top: 10px;
  margin-top: 10px;
}
.cart-item:first-of-type {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}
.cart-item-image {
  width: 15%;
  border: 1px solid #ffd6b1;
  padding: 5px;
  background: #f3f3f3;
  height: fit-content;
}
.cart-item-detail {
  flex-grow: 1;
  position: relative;
}
.cart-item-title {
  color: #103178;
  overflow-wrap: anywhere;

}
.cart-item-sold {
  font-size: 12px;
  font-weight: 500;
  color: #999797;
}
.cart-item-price>div {
  column-gap: 8px;
  padding: 5px 0;
  align-items: center;
}
.cart-item-amount {
  font-size: 22px;
  color: #fd8d27;
  font-weight: 500;
}
.cart-item-mrp {
  font-size: 22px;
  color: #afafaf;
  text-decoration: line-through;
  font-weight: 300;
}
.cart-item-discount {
  font-size: 17px;
  color: #3da73d;
  font-weight: 500;
}
.cart-item-quantity h6 {
  color: #878787;
  font-size: 12px;
  font-weight: 500;
}
.cart-item-quantity-inner {
  margin-top: 5px;
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
}
.cart-item-quantity-inner span {
  border: 1px solid #ddd;
  width: 25px;
  height: 30px;
  background: #e7dfdf;
  line-height: 25px;
  text-align: center;
  color: #333;
  display: inline-block;
}
.cart-item-quantity-inner span:nth-child(2) {
  width: 50px;
  background: #fff;
}
.cart-item-remove{
  /* position: absolute;
  top: 0px;
  right: 5px; */
  color: white;
  border: 1px solid #ff5151;
  padding: 5px 5px 3px;
  border-radius: 50px;
  line-height: 14px;
  background: #ff5151;
  font-size: 16px;
  height: 30px;
  width: 30px;
}
.section-item-summery {
  padding: 10px 0;
}
.section-summery-value {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #ffd5d5db;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.section-summery-value>div {
  color: #333;
  font-size:18px;
}
.section-summery-value:last-of-type {
  border-bottom: none;
}
.section-summery-value:last-of-type div {
  font-weight: 700;
  color: #fd8d2e;
}
.section-summery-value:nth-last-child(2) {
  border-bottom: 1px solid #333;
}
.section-button {
  width: 50%;
  background: #103178;
  border: 1px solid #103178;
  line-height: 45px;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.section-outline-button {
  width: 50%;
  background: #fff;
  border: 1px solid #103178;
  line-height: 45px;
  color: #103178;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
}
.item-quantity .item-quantity-inner span button {
  width: 100%;
  height: 100%;
}
.section-summery-btn {
  display:flex;
  column-gap: 10px;

}
.addressListItems {
  padding-left: 30px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
  position: relative;
}
.addressRadio {
  position: absolute;
  top: 3px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #103178;
  border-radius: 50%;
  background: #f3f3f3;
}
.addressRadioChecked {
  width: 12px;
  height: 12px;
  background: #e68827;
  display: block;
  border-radius: 50%;
  margin: 2px;
}
.addressTitle {
  font-weight: 700;
  font-size: 18px;
  color: #282c3f;
  text-transform: capitalize;
  word-break: break-all;
}
.addressDetail {
  color: #333;
  font-size: 14px;
  margin: 10px 0;
}
.addressMobile {
  color: #333;
  margin-bottom: 10px;
}
.addressButtons button {
  border: 1px solid #103178;
  padding: 3px 6px;
  margin-right: 5px;
  color: #333;
  border-radius: 3px;
  font-size: 13px;
}
.addressNewBlock {
  column-gap: 10px;
  flex-wrap: wrap;
  /* width: 100%; */
}
.address-line {
  width: 49%;
  margin-bottom: 10px;
}
.address-line {
  width: 49%;
  margin-bottom: 10px;
}
.address-line.textarea {
  width: 100%;
}
.franchise-form {
    padding: 40px;
    margin: 40px;
    border: 1px dashed #ffc000;
    border-radius: 12px;
}
.franchise-form .header {
  text-align: left;
  font-family: sans-serif;
  color : #ffc000;
  margin-bottom: 30px;
}
.franchise-form .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fccb3d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(251 208 85 / 39%);
}
.franchise-form .form-control {
  margin-bottom: 12px;
}
.franchise-form .form-group label {
  margin-bottom: 10px;
}
.address-line label {
  color: #fd8c29;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 3px;
  display: block;
}
.addnew button {
  background: #103178;
  color: #fff;
  padding: 10px 40px;
  margin-right: 10px;
}
.addnew button:last-of-type {
  background: #fd8c29;
}

.addAddressBtn {  
  border-top: 1px solid #ddd;
  padding-top: 15px;
  margin-top: 15px;
}
.addAddressBtn button {  
  border: 1px solid #103178;
  padding: 5px 10px;
  margin-right: 10px;
}
.addAddressBtn button:first-of-type {  
  color: #103178;
}
.addAddressBtn button:last-of-type {  
  background: #103178;
  color: #fff
}
.checkoutSectionLeft {
  width:70%;
}
.checkoutSectionLeft .addressListBlock {
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px dashed #fc8c2b;
  border-radius: 10px;
  overflow: hidden;
}
.checkoutSectionLeft .section-title {
  padding: 20px;
  border: none;
  margin-bottom: 0;
}
.adreesarea , .paymentarea {
  padding: 20px;
  border-top: 1px solid #ddd;
}
.afterSelection {
  padding: 0 20px 20px;
  margin-top: -15px;
  color: #333;
  font-size: 13px;
  position: relative;
}
.afterSelection button {
  position: absolute;
  top: -28px;
  right: 20px;
  border: 1px solid #333;
  padding: 5px 10px;
  text-transform: uppercase;
  color: #103178;
  font-weight: 700;
}
.doneIconcheckOutPro {
  position: absolute;
    top: -75%;
    left: 180px;
    color: white;
    background: green;
    font-size: 20px;
    border-radius: 50%;
}
.addressListBlock .section-title.active {
  background: #103178;
  color: #fff;
}
.payTitle {
  font-weight: 700;
  font-size: 18px;
  color: #282c3f;
  text-transform: capitalize;
  word-break: break-all;
  display: grid;
}
.payTitle small {
  font-weight: 500;
  font-size: 13px;
  color: #696a6a;
}
.placeOrderButton {
  background: #e68827;
  border: 1px solid #e68827;
  padding: 12px 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.placeOrderButton:disabled {
  opacity: .5;
}
.section-main-confirm {
  /* min-height:300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.section-main-confirm .text1 {
  font-size: 40px;
  font-weight: 700;
  color: #2ec176;
}
.section-main-confirm .text2 {
  font-size: 40px;
  color: #103178;
  font-weight: 500;
  text-transform: uppercase;
}
.section-main-confirm .text3 {
  color: #666;
  width: 46%;
  text-align: justify;
  margin-top: 10px;
}
.confirm-btn {
  margin-top: 20px;
}
.confirm-btn a:first-of-type {
  background: #ffffff;
  padding: 4px 10px 8px;
  border: 1px solid #333;
  color: #103178;
  border-radius: 4px;
  margin-right: 10px;
  text-decoration: none;
}
.confirm-btn a:last-of-type {
  text-decoration: none;
  background: #103178;
  padding: 4px 10px 8px;
  border: 1px solid #333;
  color: #fff;
  border-radius: 4px;
}
.account_dropdown {
  display:none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 230px;
  background: #fff;
  z-index: 9;
  font-size: 16px;
  box-shadow: 0px 2px 15px 0 #a59e9eb3;
  border-radius: 5px;
  padding: 5px 0;
  font-weight: 500;
}
.account_dropdown ul {
  padding-left:  1rem;
}
.account-main-icon:hover .account_dropdown {
  display:block
}
.account_dropdown::before {
  content: "";
  border: 10px solid transparent;
  position: absolute;
  top: -21px;
  left: 18px;
  border-bottom-color: #ffc000;
}
.account_dropdown li {
  list-style: none;
}
.account_dropdown li a {
  padding: 10px 20px 10px 0px;
  border-bottom: 1px solid #efefef;
  align-items: center;
  display: flex;
  column-gap: 10px;
  color: #103178!important;
  text-decoration: none;
  cursor: pointer;
}
.account_dropdown li .accountIcon {
  color: #FD8D27;
  font-size: 23px;
  padding-top: 4px;
}
.account_dropdown a:last-of-type li {
  border-bottom: none;
}
.account-section {
  column-gap: 20px;
}
.account-aside{
  width: 25%;
}
.account-profile-block {
  display: flex;
  column-gap: 10px;
  padding: 15px 10px;
  background: #fff;
  position: relative;
  margin-bottom: 20px;
}
.account-profile-block img {
  width: 50px;
  padding: 1px;
  border: 2px dashed #fdbe00;
  border-radius: 50%;
}
.account-person {
  color: #103178;
  font-size: 20px;
  display: grid;
  line-height: 18px;
  margin-top: 10px;
}
.account-person small {
  font-size: 70%;
  color: #666;
  font-weight: 400;
}
.edit-profile-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color:#103178;
  font-size:15px;
}
.account-pages-block{
  padding-left: 0
}
.account-pages-block li {
  list-style: none;
  padding: 15px 10px;
  background: #fff;
  font-size: 17px;
  color: #1031a3;
  border-bottom: 2px solid #f1f1f1;
}
.account-pages-block li a {
  display: flex;
  align-items: center;
  column-gap: 15px;
  position: relative;
  text-decoration: none;
  color : #103178
}
.account-pages-block li a .icon-navi {
  position: absolute;
  right: 0;
  top: 9%;
  font-size: 25px;
  color: #777;
}
.account-pages-block li .accountIcon {
  font-size: 22px;
  color: #FD8D27;
}
.account-main-area {
  width: 75%;
  position: relative !important;
}
.account-main-inner {
  background-color: #fff;
  position: relative;
}
.area-title, .order-title {
  border-bottom: 2px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.area-title h4 {
  font-size: 20px;
  color: #103178;
  font-weight: 500;
  text-transform: uppercase;
}
.area-top-btn {
  color: #103178;
  font-size: 15px;
}
.area-info {
  padding: 20px;
  border-bottom: 2px solid #f1f1f1;
}
.area-info label {
  font-size: 14px;
  color: #FD8D27;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
}
.area-info input {
  font-size: 18px;
  padding: 10px;
  border: 1px solid #d3d3d3;
  margin-right: 10px;
  color: black;
}
.area-info>div {
  margin-bottom: 20px;
}
.area-info>div:last-of-type {
  margin-bottom: 0px;
}

/* Radio Button */
.radio-btn {
  display: flex;
  align-items: center;
  margin-right: 20px;
  column-gap: 10px;
  color: #333;
}
.radio-btn .radio-btn-circle {
  width: 15px;
  height: 15px;
  background-color: #f7f5f5;
  border: 1px solid #103178;
  border-radius: 50%;
  padding: 1px;
}
.radio-btn .radio-btn-circle .radio-btn-check {
  width: 11.5px;
  height: 11.5px;
  background-color: #103178;
  border-radius: 50%;
}
.account-submit-btn {
  padding: 10px 15px;
  background-color: #103178;
  border: 1px solid #103178;
  color: #fff;  
  width: 120px;
}
.account-cancel-btn {
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #103178;
  color: #103178;
  width: 120px;
}
.area-info[disabled] {
  cursor: no-drop;
  pointer-events: none;
  opacity: 0.7;
}
.area-info[disabled] .account-submit-btn,
.area-info[disabled] .account-cancel-btn {
  display: none;
}
.area-order {
  padding: 20px;
  border-bottom: 2px solid #f1f1f1;
  display: flex;
  column-gap: 20px;
}
.order-image {
  width: 15%;
  border: 1px solid #ffd6b1;
  padding: 5px;
  background: #f3f3f3;
  position: relative;
  height : fit-content
}
.more-item {
  position: absolute;
  font-size: 10px;
  background: #fd8d27;
  color: #fff;
  border-radius: 20px;
  padding: 1px 7px 2px;
  bottom: 6px;
  right: 6px;
}
.orderId {
  color: #fd8d27;
  font-size: 15px;
}
.order-content {
  flex-grow: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.order-price {
  font-size: 16px;
  color: #103178;
}
.order-deatil {
  display: flex;
  justify-content: flex-end;
}
.order-deatil-btn {
  padding: 2px 15px;
  background-color: #103178;
  border: 1px solid #FD8D27;
  border-radius: 20px;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}
.order-deatil-btn:hover{
color:#FD8D27
}
.order-delivery {
  font-weight: 700;
  color: #21a938;
  font-size: 18px;
}
.order-content-detail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.order-title h5 {
  color: #103178;
  font-weight: 500;
}
.section-cancel{
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.section-cancel h4{
  font-size: 19px;
  color: red;
}
.section-cancel button{
  background: red;
  padding: 5px 10px 4px;
  margin-top: 10px;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
}

.return-btn button{
  background: #f39a09;
  padding: 5px 13px 5px;
  margin-top: 10px;
  border-radius: 15px;
  color: #fff;
  font-size: 15px;
  }
  .returned { 
    margin-top: 10px;
    color: rgb(243, 154, 9);
    font-size: 15px;
    padding: 5px;
    border-radius: 5px;
    border-color: rgb(243, 154, 9);
    border: 1px solid;
  }
  .ml-auto{
    margin-left: auto;
  }
.success {
  color: #fff;
  padding:5px 10px;
  font-size: 12px;
  /* background: green; */
  margin-left: 5px;
  border-radius: 20px;
}
.bank-div {
  width: 55%;
  margin: auto;
}
.bank-div .address-line {
  width: 100%;
}
.pending {
  color: #fff;
  padding:5px 10px;
  font-size: 12px;
  background: green;
  margin-left: 5px;
  border-radius: 20px;
}
.cancelled {
  color: #fff;
  padding:5px 10px;
  font-size: 12px;
  background: green;
  margin-left: 5px;
  border-radius: 20px;
}
.order-detail-delivery {
  border-bottom: 2px solid #f1f1f1;
  padding: 20px;
}
.order-detail-delivery h5 {
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 500;
}
.order-detail-delivery h5 strong, .order-title h5 strong {
  color: #103178;
}
.myAddressList .addressListItems {
  border-bottom: 2px solid #f1f1f1;
  padding: 20px;
  margin-bottom: 0;
}
.myAddressNew {
  color: #fff;
  align-items: center;
  display: flex;
  column-gap: 9px;
  border: 1px solid #333;
  padding: 5px 10px;
  background: #103178;
}
.AddressNewSection {
  padding: 20px;
}
.wishlist-title {
  padding: 0 15px 15px;
  font-size: 18px;
  color: #103178;
  text-transform: uppercase;
}
.menu-user-section {
  background: #103178;
  height: 110px;
  border-bottom: 5px solid #FD8D27;
  display: flex;
  align-items: flex-end;
  padding: 5px;
}
.menu-user-section img {
  width: 31%;
  border: 2px dashed #fd8d27;
  border-radius: 50%;
  padding: 1px;
}
.menu-list a {
  display: flex;
  width: 100%;
}
.login-page {
  /* height: 70vh; */
  display: flex;
  padding : 100px 0;
}
.login-page .login-outer div img {
  height : 130px;
  margin: auto;
  display: flex;
}
.login-page .login-outer {
  width: 30%;
  margin: auto;
}
.login-inner h2 {
  font-size : 30px;
  font-weight : 500;
  color : black;
  margin-bottom: 16px;
}
.login-page .login-inner {
  border:1px dashed #fec620;
  border-radius: 6px;
  padding: 25px;
}
.form-group {
  display: flex;
  flex-direction: column;
}
.form-group {
  margin-bottom: 9px;
  color : black;
  font-weight: 600;
}
.login-page .login-id{
  display: flex;
  flex-direction: column;
}
.login-page  label{
  margin-bottom: 14px;
  color : black;
  font-weight: 600;
}
.login-page .login-id input {
  border: 1px solid lightgrey;
  height: 39px;
  /* margin-bottom: 30px; */
  color : black;
  padding-inline: 20px;
  border-radius: 20px;
}
.login-page  label {
  margin-bottom: 12px;
  color : #766941;
}
.btn-primary {
  background: #ffc000;
  background: -webkit-linear-gradient(top,#f7dfa5,#ffc000);
  background: linear-gradient(to bottom,#f7dfa5,#ffc000);
  color : black;
  width: 100%;
  height: 40px;
  border : 1px solid lightgrey
}
.mt-2 {
  margin-top: 20px;
  color  :black
}
.a-divider.a-divider-break {
  text-align: center;
  position: relative;
  top: 2px;
  padding-top: 1px;
  margin-bottom: 24px;
  line-height: 0;
  margin-top : 20px;
}
.a-divider.a-divider-break::after {
  content: "";
  width: 100%;
  background-color: transparent;
  display: block;
  height: 1px;
  border-top: 1px solid #e7e7e7;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  z-index: 1;
}
.a-divider.a-divider-break h5 {
  line-height: 1;
  font-size: 16px;
  color: #767676;
  font-weight: 400;
  z-index: 2;
  position: relative;
  display: inline-block;
  background-color: #fff;
  padding: 0 8px 0 7px;
  letter-spacing: 4px;
}
.btn-secondary {
  background: #e7e9ec;
  background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec);
  background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
  width: 100%;
  color: black;
  height : 40px;
  border: 1px solid darkkhaki;
  padding: 5px;
}
.login-outer .btn-secondary span {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gender-select {
  height: 50px;
  background: #eff0f3;
  background: -webkit-linear-gradient(top,#f7f8fa,#e7e9ec);
  background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
  padding-inline: 20px;
}
.gender-select option{
  height: 40px;
}
 .login-inner .login-id {
  position: relative;
}
.otp-btn {
  position: absolute;
  top: 36px;
  background: rgb(143 143 143);
  color: #fff;
  right: 0px;
  height: 39px;
  padding: 9px;
  cursor: pointer;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.otp-btn button {
  color : white;
  cursor: pointer;
}
.otp-btn:hover {
  background: rgb(143 143 143);
  color: rgb(43, 42, 42);
}
.loaderInner {
  background-color: #ffffff;
  display: inline-flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0 0 20px 0 #d2d2d2;
}
/* .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
} */
/* 
  .navbar-bottom {
    display: none;
    position: fixed;
    top: 0;
    left: -11px;
    width: 102%;
    background: #1e1e1eab!important;
}
  .navbar-block {
    flex-direction: column;
    width: 90%;
    background: #eee;
    padding-left: 10px;
    min-height: 100vh;
    justify-content: flex-start;
  }
*/
.login-id  input:focus-visible  {
  outline-width: 1px;
  color : grey;
  border-color: grey;
}

.otp-btn button  {
  background-color: #8f8f8f !important;
}
.otp-btn button span {
  color : white;
}
.otp-btn-2 {
  position: absolute;
  top: 35px;
  background: rgb(143 143 143);
  color: white;
  right: 0px;
  height: 39px;
  padding: 9px;
  cursor: pointer;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.position-relative {
  position: relative !important;
}
.login-page input[type="radio"] {
  margin: 0;
  height: unset !important;
}

.login-page .gender {
  color: grey;
}
select {
  color : black
}

.noDataFound{
  width: 150px;
  margin-bottom: 10px;
}
a.section__product {
  text-decoration: none;
}
.position-loader {
  height : 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.position-loader2 {
  height : 300px;
  display: flex;
  justify-content: center;
  /* position: relative; */
  align-items: initial;
}
.position-loader-cartSummary {
  height : 205px;
  display: flex;
  justify-content: center;
  align-items: initial;
  /* position: relative; */
}
.address-blank {
  height:  220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.address-blank img {
  height : 110px;
}
.needed {
  position: relative;
  width: 100%;
}
.needed > div {
  top : 0 !important
}
.account-main-area .all-product .product__image {
  width: 100%;
}
.fliter-btn-div {
  display: none;
}
.m404 {
  height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.m404 p {
  font-size: 68px;
  margin: 0;
}
.m404 p:nth-child(2) {
  font-size: 25px;
}

.cancel-btn-product {
  background: red;
  padding: 5px 10px 4px;
  margin-top: 10px;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
}
.cancel-btn-product.ml-auto{
  margin-left: auto;
}
.btnLoader {
  --dot1: #788c9f;
  --dot2: #788c9f33;
  --dot3: #788c9f66;
  --dot4: #788c9fb3;
  --shadow1: 96px;
  --shadow2: 48px;
  --shadow3: -48px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--dot3);
  box-shadow: var(--shadow1) 0 var(--dot1), var(--shadow2) 0 var(--dot2), var(--shadow3) 0 var(--dot4);
  position: relative;
  animation: 700ms linear 1ms infinite alternate flash;
}

@keyframes flash {
  0%, 100% {
	background-color: var(--dot4);
    box-shadow: var(--shadow1) 0 var(--dot2), var(--shadow2) 0 var(--dot3), var(--shadow3) 0 var(--dot1);
  }
  25% {
    background-color: var(--dot1);
    box-shadow: var(--shadow1) 0 var(--dot3), var(--shadow2) 0 var(--dot4), var(--shadow3) 0 var(--dot2);
  }
  50% {
	background-color: var(--dot2);
    box-shadow: var(--shadow1) 0 var(--dot4), var(--shadow2) 0 var(--dot1), var(--shadow3) 0 var(--dot3);
  }
  75% {
    background-color: var(--dot3);
    box-shadow: var(--shadow1) 0 var(--dot1), var(--shadow2) 0 var(--dot2), var(--shadow3) 0 var(--dot4);
  }
}


.bankDetails-line label {
  color: #fd8c29;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 3px;
  display: block;
}

.bankDetailsHeader{
  color: #fd8c29;
  font-size: 16px;
  font-weight: 500;
}
.bankDetailsHeader span{
  color: #333;
  font-size: 15px;
}
.bankDetailsData{
  padding: 20px;
  border: 1px dotted;
}

/*  css for new nav header bar mega menu */
.navbar-list.mega-menu  .mega-submenu {
  display: none;
}
.navbar-list.mega-menu:hover  .mega-submenu {
  display: block;
  height: 72vh;
  left: 80px;
  background-color: #fff;
  padding: 10px 20px;
  position: absolute;
  top: 100%;
  width: 92vw;
}
.navbar-list.mega-menu:hover .mega-submenu ul {
  display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
}
.bankpage[disabled] {
  cursor: no-drop;
  pointer-events: none;
  opacity: 0.5;
}


.navbar-list.mega-menu:hover .mega-submenu ul li {
  list-style-type: none;
} 
.navbar-list.mega-menu:hover .mega-submenu ul li.sub-head {
  margin: 12px 0 8px ;
}
.react-slider__ul li.active img {
  height: fit-content;
}
.react-slider__ul {
  height : 56px
}
.bankpage {
  padding: 20px;
}
.bankpage .btn {
  margin-top: 12px;
}
/*  css for new nav header bar mega menu */

@media (min-width: 480px){
.fluid__instructions {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    padding-top: 30px;
}
}







