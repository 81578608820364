/* Large, Medium Devices */
@media (max-width: 2000px){
    .each-slide > div {
      height : 720px !important
    }
  }
@media (max-width: 1800px){
    .each-slide > div {
      height : 720px !important
    }
  }
@media (max-width: 1600px){
    .each-slide > div {
      height : 700px !important
    }
  }
@media only screen and (min-width: 991px){
    .responsive-md-lg .page-filter {
        display: none;
    }
    .mble-menu-btn {
        display: none;
    }
}
/* Only Large Devices */
@media only screen and (min-width: 1200px){
}

/* Only Medium Devices */
@media only screen and (min-width: 991px) and (max-width:1200px){
}

/* Only Small Devices */
@media only screen and (min-width: 546px) and (max-width:991px){
    .responsive-sm .featureBlock .section__product {
        width: 24.99%;
    }
    .responsive-sm aside.slidepanel {
        width: 30%;
    }
    .responsive-sm .all-product .section__product {
        width: 31.3%;
    }
    .responsive-sm .sectionLeft {
        margin-bottom: 20px;
    }
    .responsive-sm .sectionLeft, .responsive-sm .sectionRight, .responsive-sm .checkoutSectionLeft {
        width: 100%;
    }
    .responsive-sm .section-main-area {
        flex-direction: column
    }
}

/* Only Mobile Devices */
@media only screen and (max-width: 546px) {
    
    .all-product .product__image {
        height : unset !important
    }
    .d-flex,.row {
       flex-direction: column;
    }
    .row {
       margin: 0;
    }
    .responsive-xs .container {
        max-width: 100%;
        width: auto;
        padding-right: 10px;
        padding-left: 10px;
        margin-right: auto;
        margin-left: auto;
    }
    .responsive-xs .navbar {
        align-items: flex-start;
        flex-direction: column;
    }
    .navbar-bottom {
        display:none;
    }
    .navbar .comp-logo img {
        max-width: 170px;
    }
    .navbar .search-table {
        width: 100%;
        margin-bottom: 10px;
        margin-top: -5px;
    }
    .navbar-account {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .navbar-account .icon {
        width: 36px;
        height: 38px;
        font-size: 23px;
    }
    .navbar-account .icon .badge {
        width: 15px;
        height: 15px;
        font-size: 9px;
        left: 25px;
        top: -3px;
    }
    .responsive-xs .search-table .form-control {
        font-size: 14px;
        width: 85.3%;
        padding: 0 15px;
    }
    .responsive-xs .input-group-append {
        width: 14%;
    }
    .responsive-xs .mobileNavbar {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        background: #36528ec9;
        height: 100%;
        width: 100%;
    }
    .responsive-xs .mobileNavbarInner {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 80vw;
        background: #ededed;
    }
    .responsive-xs .sub-menu-list.is-open {
        padding: 5px;
        padding-left: 12px;
        height: auto;
        overflow: hidden;
        background: #fff;
        margin: 5px 0;
        border-radius: 5px;
        font-size: 13px;
    }

    /* Slider */
    .responsive-xs .each-slide > div {
        height: 185px !important;
    }
    .responsive-xs .each-slide span {
        top: 13%;
        left: 5%;
        font-size: 25px;
        width: 82%;
        line-height: 26px;
    }
    .responsive-xs .homeSlider .react-slideshow-container .default-nav:first-of-type {
        margin-right: -40px;
        transform: translateX(10px);
    }
    .responsive-xs .homeSlider .react-slideshow-container .default-nav:last-of-type {
        margin-left: -40px;
        transform: translateX(-10px);
    }
    .responsive-xs .homeSlider .react-slideshow-container .nav.default-nav {
        height: 40px;
        width: 40px;
        background: #ffffffab;
    }
    .responsive-xs .featureBlock .section__product {
        width: 49.7%;
    }
    .responsive-xs .homeProduct .section-title h4 {
        font-size: 21px;
        margin-bottom: 0;
    }
    .responsive-xs .homeProduct .viewAllBtn {
        padding: 2px 8px;
        font-size: 11px;
    }
    .responsive-xs .homeProduct .product__content {
        padding: 10px;
    }
    .responsive-xs .col-md-4{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .responsive-xs .bot-block{
        margin-bottom:10px
    }
    .responsive-xs .slidepanel {
        width: 100%;
    }
    .responsive-xs .slidepanel.mobl {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 9;
        background: #232a7ead;
        left: 0;
        height: 100vh;
        overflow: scroll;
        padding: 10px;
    }
    .responsive-xs .mainpanel {
        width: 100%;
    }
    .responsive-xs .all-product {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        padding-bottom: 40px;
    }
    .responsive-xs .all-product .section__product {
        width: 48.5%;
        margin-right: 0;
    }
    .responsive-xs .page-title h6 {
        font-size: 9px;
    }
    .responsive-xs .page-filter h5 {
        display: none;
    }
    .responsive-xs .page-filter {
        position: absolute;
        right: 0;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: .0;
        padding: 4px;
        padding-right: 2px;
        justify-content: center;
    }
    .responsive-xs .footerInnerBlock {
        margin-left: 0;
        width: 100%;
        margin-right: 0;
        padding: 0 10px;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .responsive-xs .footerInnerBlock .foot-side {
        width: 45%;
        flex: 1 1 50%;
        margin-bottom: 20px;
    }

    /* cart */
    .responsive-xs .order-pagination ul::before {
        width: 61%;
        border: 1px dashed #fd8d27;
    }
    .responsive-xs .order-pagination ul li {
        padding: 0 5px;
        font-size: 20px;
    }
    .responsive-xs .order-pagination ul {
        column-gap: 15px;
        margin: 0;
    }
    .responsive-xs .item-slider, .responsive-xs .item-detail{
        width: 100%;
    }
    .responsive-xs .item-size , .responsive-xs .delivery-resposive {
        margin-left: 0;
    }
    .responsive-xs .item-availability-input-btn {
        display: flex;
        width: 100%;
        position: relative;
        height: 60px;
    }
    .responsive-xs .item-availability>div {
        flex-direction: column;
    }
    .responsive-xs .item-size-inner.d-flex{
        flex-direction: row;
    }
    .responsive-xs .cartLeft {
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
    }
    .responsive-xs .cartRight {
        width: 100%;
        padding: 10px;
    }
    .responsive-xs .section-button , .responsive-xs .section-outline-button {
        font-size: 13px;
    }
    .responsive-xs .cart-item-quantity {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
    .responsive-xs .cart-item-price>div {
        flex-direction: row;
    }
    .responsive-xs .cart-item-image {
        width: 50%;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
    }
    .responsive-xs .cart-item-remove {
        top: -36px;
        right: 21%;
    }
    .responsive-xs .sectionLeft {
        width: 100%;
        margin-bottom: 20px;
    }
    .responsive-xs .sectionRight {
        width: 100%;
    }
    .responsive-xs .checkoutSectionLeft {
        width: 100%;
    }
    .responsive-xs .addressButtons {
        flex-direction: row;
    }
    .responsive-xs .addnew {
        display: flex;
    }
    .responsive-xs .addnew button {
        padding: 7px 17px;
        font-size: 11px;
    }
    .responsive-xs .doneIconcheckOutPro {
        top: -35%;
    }
    .responsive-xs .afterSelection button {
        padding: 3px 10px;
    }
    .copyright {
        font-size: 11.5px;
    }
    .responsive-xs .icon-holder {
        flex-direction: row-reverse;
    }
    .responsive-xs .icon-holder li:first-of-type {
        display: none;
    }
    .responsive-xs .comp-logo {
        display: flex;
        flex-direction: row-reverse;
    }
    .responsive-xs .mble-menu-btn {
        font-size: 30px;
        color: #333;
        margin-right: 10px;
    }
    .responsive-xs .mble-menu-close {
        font-size: 30px;
        color: #5c73a2;
        position: fixed;
        top: 1px;
        right: 20px;
        background: #ffffff;
        padding: 10px;
        height: 50px;
        border-radius: 0 15px 15px 0;
    }
    .responsive-xs .account-main-area {
        width: 100%;
    }
    .responsive-xs .area-order {
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }
    .responsive-xs .order-image {
        width: 80%;
        text-align: center;
    }
    .responsive-xs .account-aside {
        width: 100%;
    }
    .responsive-xs .account-pages-block {
        display:none
    }
    .homeCategory .section-title h4 {
        font-size: 17px !important;
    }
    .homeCategory .category__content {
        text-align: left;
    }
    .homeCategory .category__name  {
        font-size: 20px;
    }
    .homeCategory .category__image{
        width: 70%;
    }
    .homeProduct .section__product {
        width: 100% !important;
    }
    .homeProduct .product__image{
        width: 100% !important;
    }
    .homeProduct .product__title {
        height: 38px !important;
        overflow: hidden !important;
    }
    .delivery {
        padding: 30px 16px !important;
    }
    .homeProduct .product__title {
        line-height: 21px !important;
    }
    .responsive-xs .mble-menu-close svg {
        position: relative !important;
        top: -10px !important;
    }
    .user-info h6 {
        color : white !important;
        
    }
    .menu-user-section .user-info {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .menu-user-section img {
        width: 40% !important;
    }
    .menu-list a {
        text-decoration: none !important;
    }
    .menu-list a {
        color: #103178 !important;
    }
    .login-page .login-outer {
        width: 90% !important;
    }
    .account-section .address-line {
        width:  100%;
    }
    .all-product .section__product .product__actions {
        top: 10px;
        right: 20px;
        opacity: 1;
    }
    .product-page .page-title  {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .fliter-btn-div {
        position: absolute;
        top: 0;
        right: 0;
        display: block !important;
    }
    .fliter-btn {
        background: #103178;
        color: white;
        width: fit-content;
        padding: 5px 18px;
        border-radius: 7px;
    }
    .showfilter {
        display: block;
    }
    .hidefilter {
        display: block;
    }
    .cart-item:first-of-type {
        position: relative !important;
    }
    .responsive-xs .cart-item-remove {
        top: unset !important;
        bottom : 0;
        position: absolute;
        right: 0;
    }
    .order-pagination ul {
        justify-content: space-between !important;
    }
    .addressNewBlock .address-line {
        width: 100%;
    }
    .responsive-xs .slidepanel .filter-pro.is-open-pro {
        height: 0 !important;
        overflow: hidden !important;
        transition: .1s ease-in-out !important;
    }
    .responsive-xs .slidepanel .filter-pro {
        height: auto;
        overflow: auto;
        background: #eee;
    }
    .mobile-menu-inner li button {
        font-size: 14px;
    }
    .mobile-menu-inner > ul > li {
        margin-bottom: 10px;
    }
    .item-title {
        margin-top: 20px;
        font-size: 23px !important;
    }
    .react-slider__imgZoom {
        display: none !important;
    }
    .item-detail .item-price .current-price {
        font-size: 40px !important;
    }
    .item-detail .item-price .old-price {
        font-size: 25px  !important;
    }
    .item-detail .item-price .save-price {
        font-size: 16px !important;
    }
    .product-detail-bottom>div {
        padding: 0  !important;
    }
    section.footerInner {
        padding: 30px 0 30px;
    }
    .foot-side {
        flex : unset  !important;
        width: 50% !important;
        margin-bottom: 20px;
    }
    .footerInner .container .row  {
        width: 100% !important; 
        flex-direction: revert !important;
    }
    .footerInner .address-area p {
        line-height: unset !important;
        font-size: 13px;
        color: #ffffff;
        display: block;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
        overflow-wrap: anywhere;
    }
}
@media only screen and (max-width: 430px) {
    .order-deatil-btn {
        width:  100%;
      }
      .area-order {
        padding: 0;
        margin-bottom: 12px;
      }
      .responsive-xs .order-image {
        width: 100%;
      }
      .order-content {
        width: 100%;
      }
      .scroll-div {
        /* height : 300px; */
        overflow-y: scroll;
       }
    .all-product .product__image {
        height: 215px !important;
    }
    .area-title, .order-title {
        flex-direction: column;
        align-items: unset !important;
        padding: 12px !important;
    }
    .area-title, .order-title h5 {
        font-size: 17px;
    }
    .order-content-detail .d-flex.align-items-center.w-100 {
        align-items: unset !important;
    }
    .order-content-detail .d-flex.align-items-center {
        align-items: unset !important;
    }
    .text-decoration-none.bread-items {
        font-size: 17px;
    }
    .product-page .page-title h6 {
        flex-wrap : wrap
    }
    .account-main-inner .area-info div:nth-child(2) > div.d-flex {
        flex-direction: row;
    }
    .account-main-inner .myAddressList .addressButtons button {
        width: 50%;
        font-size: 15px;
    }
    .account-main-inner .AddressNewSection .addAddressBtn {
        display: flex;
        flex-direction: row;
    }
    .account-main-inner .AddressNewSection .addAddressBtn button {
        width: 50%;
        font-size: 15px;
    }
    .account-section .order-deatil-btn button {
        padding: 6px 15px;
        font-size: 20px;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 394px) {
    .footerInner .container .row  {
        width: 100% !important; 
        flex-direction: revert !important;
    }
    .footerInner .address-area p {
        line-height: unset !important;
        font-size: 13px;
        color: #ffffff;
        display: block;
        font-weight: 500;
        line-height: 20px;
        text-decoration: none;
        overflow-wrap: anywhere;
    }
    .foot-side {
        flex : unset  !important;
        margin-bottom: 20px;
        width: 50% !important;
    }
    .item-detail .item-price .old-price {
        font-size: 25px  !important;
    }
    .product-detail-bottom>div {
        padding: 0  !important;
    }
    .item-detail .item-price .save-price {
        font-size: 16px !important;
    }
    .item-detail .item-price .current-price {
        font-size: 40px !important;
    }
    .item-title {
        margin-top: 20px;
        font-size: 23px !important;
    }
    .react-slider__imgZoom {
        display: none !important;
    }
    .responsive-xs .each-slide > div {
        height: 185px !important;
    }
    .homeCategory .section-title h4 {
        font-size: 17px !important;
    }
    .homeCategory .category__content {
        text-align: left;
    }
    .homeCategory .category__name  {
        font-size: 20px;
    }
    .homeCategory .category__image{
        width: 70%;
    }
    .homeProduct .section__product {
        width: 100% !important;
    }
    .homeProduct .product__image{
        width: 100% !important;
    }
    .homeProduct .product__title {
        height: 38px !important;
        overflow: hidden !important;
    }
    .delivery {
        padding: 30px 16px !important;
    }
    .homeProduct .product__title {
        line-height: 21px !important;
    }
    .responsive-xs .mble-menu-close svg {
        position: relative !important;
        top: -10px !important;
    }
    .user-info h6 {
        color : white !important;
    }
    .menu-user-section .user-info {
        display: flex;
        align-items: center;
        gap: 16px;
    }
    .menu-user-section img {
        width: 25% !important;
    }
    .menu-list a {
        color: #103178 !important;
    }
    .login-page .login-outer {
        width: 90% !important;
    }
    .all-product .section__product .product__actions {
        top: 10px;
        right: 20px;
        opacity: 1;
    }
    .fliter-btn-div {
        position: absolute;
        top: 0;
        right: 0;
        display: block  !important;
    }
    .fliter-btn {
        background: #103178;
        color: white;
        width: fit-content;
        padding: 5px 18px;
        border-radius: 7px;
    }
    .cart-item:first-of-type {
        position: relative !important;
    }
    .responsive-xs .cart-item-remove {
        top: unset !important;
        bottom : 0;
        position: absolute;
        right: 0;
        height: 45px;
        width: 45px;
    }
    .order-pagination ul {
        justify-content: space-between !important;
    }
    .addressNewBlock .address-line {
        width: 100%;
    }
    .order-deatil-btn {
        width:  100%;
    }
    .all-product .product__image {
        height: 180px !important;
    }
    .order-content-detail .d-flex.align-items-center.w-100 {
        align-items: unset !important;
    }
    .order-content-detail .d-flex.align-items-center {
        align-items: unset !important;
    }
    .product-page .page-title h6 {
        flex-wrap : wrap;
    }
    .account-main-inner .area-title, .order-title {
        flex-direction: row;
    }
    .account-section .order-deatil-btn  button{
        padding: 6px 15px;
        font-size: 20px;
        margin-top: 20px;
    }
}